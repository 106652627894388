/* ----------- LOADER COMPONENT ----------- */
.loader-container {
    position:relative;/* absolute */
    left: 0;
    right: 0;
    height: 70vh;
    z-index: 10000;
    text-align: center;
}

.loader-content {
    position: relative;
    top: 25%;
}

.loader {
    width: 4em; /* 30px [16px base font]*/
    height: 4em; /* 30px [16px base font]*/
	border-top: 10px solid aliceblue;
	border-right: 10px solid aliceblue;
	border-bottom: 10px solid aliceblue;
	border-left: 10px solid #8c618d;
	border-radius: 50%;
	animation-name: spin;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	display: inline-block;
    zoom: 1;
}
.bodyHeight{min-height:42vh;}
.loader-text {
    display: block;
	color: #4D4D4D;
    position: relative;
    text-align: center;
    margin-top: 0.5em;
    font-size: 1.5em;
    font-family: sans-serif;
    font-weight: 700;
    -webkit-animation: bounce 3s linear infinite;
            animation: bounce 3s linear infinite;
    letter-spacing: 0.5em;
}

.config-card:hover i{
    font-size: 55px;
}
.form-colorpick{
    height: 40px!important;
    padding: 5px;
}
.active-pro{
    bottom: 60px!important;
}
.pointer{
    cursor: pointer;
}